export default function Footer() {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        2023 © ino-fi solutions pvt ltd.
                    </div>
                </div>
            </div>
        </footer>
    );
}